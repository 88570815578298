<template>
    <div class="d-flex flex-column ">
        <div style="background-color: white !important; width: 25.75cm !important; margin-top: 0 !important; margin-bottom: 0 !important; padding-left: 9px;" class="overlay-print">
            <div fluid class="px-0 mx-0 py-0 my-0" style="background-color: white !important;">
                <table style="width:25.75cm !important; background-color: white !important; border-spacing: 0 2px;">
                    <table style="width:25.75cm !important; background-color: white !important; border-spacing: 0 2px;">
                        <thead>
                            <tr>
                                <td>
                                    <div class="header-space" id="header-space-div" :style="`height: 7cm;`">
                                        <div class="header " style="color: #262626 !important; width: 25.75cm;"> 
                                            <img id="header-image" :style="`width: 25.75cm !important; max-width: 25.75cm; height: 7cm !important;`" :src="logo" />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </thead>
                        <tbody style="width: 25.75cm !important; ">
                            <tr style="width: 25.75cm !important; background-color: white !important;">
                                <td style="width: 25.75cm !important; background-color: white !important;">
                                    <div class="content  pa-0" style="width: 25.75cm !important;background-color: white !important; border-top: 1px solid;">
                                        <div> {{ new Date().toISOString().split('T')[0] }} </div>
                                        <div style="color: black;" class="mt-1">
                                            <p class="text-center"> فەرمانی خەرجکردن </p>
                                            <p class="text-right mt-5" dir="rtl">
                                                {{ text }}
                                            </p>
                                        </div>
                                    </div>

                                    <table dir="rtl" class="table table-bordered table-striped text-center " style="color: black; page-break-inside: always; font-size: 12pt !important;">
                                        <thead>
                                            <th style="width: 8%; font-weight: 600;"> فۆڕم </th>
                                            <th style="width: 11%; font-weight: 600;"> بەروار </th>
                                            <th style="width: 9%; font-weight: 600;"> بڕ </th>
                                            <th style="width: 9%; font-weight: 600;"> هێند </th>
                                            <th style="width: 9%; font-weight: 600;"> نرخ </th>
                                            <th style="width: 13%; font-weight: 600;"> کۆی نرخ </th>
                                            <th style="font-weight: 600;"> پڕۆژە </th>
                                            <th style="font-weight: 600;"> شوێن </th>
                                            <th style="font-weight: 600;"> خەرجی </th>
                                        </thead>
                                        <tbody>
                                            <tr v-for="item in rows" :key="item.staff_expense_id" style="font-size: 11.5pt !important;">
                                                <td> {{ item.form }} </td>
                                                <td> {{ new Date(item.expense_date).toISOString().split('T')[0] }} </td>
                                                <td> {{ item.qty }} </td>
                                                <td> {{ item.unit }} </td>
                                                <td> {{ (item.price).toLocaleString() }} </td>
                                                <td> {{ (item.total).toLocaleString() }} </td>
                                                <td> {{ item.project_name }} </td>
                                                <td> {{ item.location }} </td>
                                                <td> {{ item.expense_text }} </td>
                                            </tr>
                                            <tr>
                                                <td colspan="4"  class="text-left para-total" style="color: white !important;"> کــۆ: </td>
                                                <td class="para-total" style="color: white !important;"> {{ (total).toLocaleString() }} </td>
                                                <td class="para-total text-right" colspan="4" style="color: white !important;"> {{ numToWord(total) }} دینار </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </td>
                            </tr>
                        </tbody>

                        <tfoot>
                            <tr>
                                <td>
                                    <div class="footer-space" :style="`height: 5cm !important; background-color: white !important;`">
                                        <div class="footer" :style="`direction: ltr; width: 25.75cm;  background-color: white !important; height: 5cm;`" >
                                            <div class="row" style="margin-top: 2cm;" dir="rtl">
                                                <div class="col-4 text-center" style="color: black;">
                                                    <div>
                                                        بەڕێوەبەر
                                                        <br>
                                                        {{ names.manager }}
                                                    </div>
                                                </div>
                                                <div class="col-4 text-center" style="color: black;">
                                                    <img :src="h_signature" width="180px" height="130px" style="margin-left: 18px; margin-top: -90px;" alt="">
                                                    <div class="mt-n10">
                                                        ژمێریار
                                                        <br>
                                                        {{ names.accountant }}
                                                    </div>
                                                </div>
                                                <div class="col-4 text-center" style="color: black;">
                                                    <div>
                                                        بەڕێوەبەری دارایی
                                                        <br>
                                                        {{ names.kargeri }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div style="display: flex; justify-content: space-between;" class="mt-5 px-1">
                                                <span>
                                                    <i class="fas fa-map-marker-alt"></i>
                                                    <span class="ms-1"> Sulaymaniyah Heights - Villa E35 </span>
                                                </span>
                                                <span>
                                                    <i class="fas fa-envelope"></i>
                                                    <span class="ms-1"> info@idealpowerco.com </span>
                                                </span>
                                                <span>
                                                    <i class="fas fa-phone-square-alt"></i>
                                                    <span class="ms-1">
                                                        0770 152 9256
                                                        -
                                                        0750 152 9256
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tfoot>

                    </table>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import KurdishSoraniNumbersToWords from 'kurdish-numbers-to-words/Sorani.js'
export default {
    props: ['names', 'rows', 'text'],
    data() {
        return {
            logo: require('../assets/expense_logo.png'),
            footerLogo: require('../assets/footer.png'),
            h_signature: require('../assets/h-signature.jpg'),
        }
    },
    methods: {
        numToWord(num) {
            return KurdishSoraniNumbersToWords.convert(num);
        },
    },
    computed: {
        total() {
            return this.rows.reduce((a, b) => a + b.total, 0);
        }
    },

    mounted() {
        setTimeout(() => {
            window.print();
            this.$emit('close-print')
        }, 100)
    }
}
</script>

<style scoped>

@page {
    padding: 0;
    margin: 0;
    height: 29.7cm;
    width: 25.75cm;
    size: A4;
}

#table td{
    border-top: none;
    border-width: 0.5px;
    font-size: 14px;
    font-weight: normal;
    color: #262626;
}

#table th{
    font-size: 12pt;
    font-weight: bold;
    color: #262626;
}


.header {
  position: fixed;
  top: 0;
}

.footer {
  position: fixed;
  bottom: 0;
}

*:not(.para, .para-total, .expired-passport-date) {
    background-color: white ;
        
}
.para {
    background-color: rgb(81, 224, 81) !important;
    color: black !important;
}
.para-total {
    background-color: rgb(68, 171, 231) !important;
    color: black !important;
}


@media print {

    @page {
        padding: 0;
        margin: 0;
        size: A4;
    }

    body {
        padding: 0;
        margin: 0;
        
    }    
}
</style>