<template>
    <div id="wrapper">
        <navbar id="nav" class="no-print animate__animated animate__fadeInLeft"  style="display: none;"/>
        <div class="d-flex flex-column animate__animated animate__fadeInRight no-print" id="content-wrapper" style="display: none !important;">
            <div id="content">
                <header-btn class="no-print"/>
                <div class="px-0-print container-fluid text-dark">
                    <div class="row px-1 py-3 mb-1 no-print">
                        <label class="mt-2 mb-2 me-3"> From: </label>
                        <input class="form-control col-xl-1 col-md-4 mb-2" v-model="from" type="number">

                        <label class="mt-2 mb-2 mx-3"> To: </label>
                        <input type="number" class="form-control col-xl-1 col-md-4 mb-2" v-model="to">
                        
                        <label class="mt-2 mx-3"> Staffs: </label>
                        <select v-model="staff_id" class="form-control col-sm-2 mb-2">
                            <option value=""></option>
                            <option v-for="staff in staffs.filter(obj => obj.special_staff == showSpecialStaffs)" :key="staff.st_id" v-bind:value="staff.st_id"> {{ staff.staff_name }} </option>
                        </select>
                        <span class="col-1">
                            <v-checkbox class="mt-1 custom-checkbox-label" v-model="showSpecialStaffs" true-value="true" false-value="false" label="Specials"></v-checkbox>
                        </span>

                        <label class=" ms-6 mt-2 me-3"> Project: </label>
                        <select v-model="project_id" class="form-control col-sm-4 mb-2 px-1">
                            <option value=""></option>
                            <option v-for="project in projects" :key="project.project_id" v-bind:value="project.project_id"> {{ project.project_name }} </option>
                        </select>

                        <label class="mt-2 me-3"> Location: </label>
                        <select v-model="location" class="form-control col-sm-4 mb-2 px-1">
                            <option value=""></option>
                            <option v-for="(location, inx) in locations" :key="'ll' + inx" v-bind:value="location.location"> {{ location.location }} </option>
                        </select>

                        <button class="btn btn-success ms-3 mb-2" style="height: 37px;" v-on:click="search()">  <i class="fa fa-search"></i> </button><hr>
                        <button class="btn btn-primary ms-3 mb-2" style="height: 37px;" v-on:click="printComponent = true">  <i class="fa fa-print"></i> </button><hr>
                    </div>
                    <hr class="no-print">
                    <div class="row no-print" dir="rtl">
                        <label class="mt-1 col-xl-1 mb-2"> بەڕێوەبەر: </label>
                        <input class="form-control col-xl-2 col-md-4 mb-2" v-model="manager" type="text" @change="changeNames()">

                        <label class="mt-1 col-xl-1 mb-2"> ژمێریار: </label>
                        <input type="text" class="form-control col-xl-2 col-md-4 mb-2" v-model="accountant" @change="changeNames()">

                        <label class="mt-1 col-xl-1 mb-2"> دارایی: </label>
                        <input type="text" class="form-control col-xl-2 col-md-4 mb-2" v-model="kargeri" @change="changeNames()">
                    </div>
                    <hr class="no-print">
                    
                    <div class="content">
                        <div> {{ new Date().toISOString().split('T')[0] }} </div>
                        <div style="color: black;" class="mt-1">
                            <p class="text-center"> فەرمانی خەرجکردن </p>
                            <p class="text-right mt-5" dir="rtl">
                                بڕیاردرا بە خەرجکردنی بڕی ({{ (total).toLocaleString() }}) {{ numToWord(total) }} دینار بۆ  {{ staff_id ? staffs.find(obj => obj.st_id == staff_id).staff_name : '' }} بۆ خەرجی 
                                {{ project_id ? 'لە پڕۆژەی ' + projects.find(p => p.project_id == project_id).project_name : '' }}
                                {{ location ? 'لە شوێنی ' + location : '' }}
                                
                                لە فۆڕمی {{ from }} بۆ فۆڕمی {{ to }} بەپێی ئەم خشتەیەی لای خوارەوە:
                            </p>
                        </div>


                        <table dir="rtl" class="table table-bordered table-striped text-center " style="page-break-inside: always; font-size: 12pt !important;">
                            <thead>
                                <th style="width: 8%; font-weight: 600;"> فۆڕم </th>
                                <th style="width: 11%; font-weight: 600;"> بەروار </th>
                                <th style="width: 9%; font-weight: 600;"> بڕ </th>
                                <th style="width: 9%; font-weight: 600;"> هێند </th>
                                <th style="width: 9%; font-weight: 600;"> نرخ </th>
                                <th style="width: 13%; font-weight: 600;"> کۆی نرخ </th>
                                <th style="font-weight: 600;"> پڕۆژە </th>
                                <th style="font-weight: 600;"> شوێن </th>
                                <th style="font-weight: 600;"> خەرجی </th>
                            </thead>
                            <tbody>
                                <tr v-for="item in expenses" :key="item.staff_expense_id">
                                    <td> {{ item.form }} </td>
                                    <td> {{ new Date(item.expense_date).toISOString().split('T')[0] }} </td>
                                    <td> {{ item.qty }} </td>
                                    <td> {{ item.unit }} </td>
                                    <td> {{ (item.price).toLocaleString() }} </td>
                                    <td> {{ (item.total).toLocaleString() }} </td>
                                    <td> {{ item.project_name }} </td>
                                    <td> {{ item.location }} </td>
                                    <td> {{ item.expense_text }} </td>
                                </tr>
                                <tr>
                                    <td colspan="4"  class="text-left para-total" style="color: white !important;"> کــۆ: </td>
                                    <td class="para-total" style="color: white !important;"> {{ (total).toLocaleString() }} </td>
                                    <td class="para-total text-right" colspan="4" style="color: white !important;"> {{ numToWord(total) }} دینار </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="footer-report">
                        <div class="row mt-15 mb-8" dir="rtl">
                            <div class="col-4 text-center" style="color: black;">
                                بەڕێوەبەر
                                <br>
                                {{ manager }}
                            </div>
                            <div class="col-4 text-center" style="color: black;">
                                ژمێریار
                                <br>
                                {{ accountant }}
                            </div>
                            <div class="col-4 text-center" style="color: black;">
                                بەڕێوەبەری دارایی
                                <br>
                                {{ kargeri }}
                            </div>
                        </div>
                        <!-- <img src="../assets/footer.png" alt="" width="100%" > -->
                    </div>

                </div>
            </div>
        </div>

        <Print v-if="printComponent" :names="{
            manager: manager,
            accountant: accountant,
            kargeri: kargeri
        }" :rows="expenses" :text="printText" @close-print="printComponent = false"/>

    </div>
</template>

<script>
import auth from '../auth';
import KurdishSoraniNumbersToWords from 'kurdish-numbers-to-words/Sorani.js'
import Print from './PrintStaffExpensesReport.vue'
export default {
    components: {
        Print
    },
    name: "staff_expenses_report",
    data() {
        return {
            staff_id: null,
            staffs: [],
            expenses: [],
            month: new Date().getMonth() + 1,
            year: new Date().getFullYear(),
            from: 1,
            to: 1,
            employees: [],
            manager: '',
            accountant: '',
            kargeri: '',
            showSpecialStaffs: "false",
            projects: [],
            project_id: null,
            location: null,
            logo: require('../assets/expense_logo.png'),
            locations: [],
            printComponent: false
        }
    },
    beforeCreate() {
        auth("staff_expenses_report");
    },
    created() {
        this.$http.get('/getNames').then(({data}) => {
            this.manager = data.manager;
            this.kargeri = data.kargeri;
            this.accountant = data.accountant;
        });
        this.$http.post('/staff/getData').then(({data})=>{
            this.staffs = data.filter(obj => obj.show_staff == '1').sort((a, b) => a.staff_sort_code - b.staff_sort_code)
        })

    },
    mounted() {
        setTimeout(() => {
            this.$http.post('/projects/getData').then(({data}) => {
                this.projects = data;
            });
            this.$http.get('/staff_expenses/getLocations').then(({data}) => {
                this.locations = data;
            });
        }, 500)
    },
    methods: {
        numToWord(num) {
            return KurdishSoraniNumbersToWords.convert(num);
        },
        search() {
            this.expenses = []
            this.$http.post('/staff_expenses/getDataByStaff', {
                staff_id: this.staff_id,
                from: this.from,
                to: this.to,
                project_id: this.project_id || null,
                location: this.location || null
            }).then(({data}) => {
                this.expenses = data;
            })
        },
        changeNames() {
            this.$http.post('/setNames', {
                manager: this.manager,
                kargeri: this.kargeri,
                accountant: this.accountant
            }).then(() => {
                
            })
        }
    },
    computed: {
        total() {
            return this.expenses.reduce((a, b) => a + b.total, 0);
        },
        printText() {
            return `بڕیاردرا بە خەرجکردنی بڕی (${(this.total).toLocaleString()}) ${this.numToWord(this.total)} دینار بۆ  ${this.staff_id ? this.staffs.find(obj => obj.st_id == this.staff_id).staff_name : ''} بۆ خەرجی 
            ${this.project_id ? 'لە پڕۆژەی ' + this.projects.find(p => p.project_id == this.project_id).project_name : ''} ${this.location ? 'لە شوێنی ' + this.location : ''} لە فۆڕمی ${this.from} بۆ فۆڕمی ${this.to} بەپێی ئەم خشتەیەی لای خوارەوە:`;
        }
    },
    watch: {
        staff_id() {
            this.search();
        },
        from() {
            this.search();
        },
        to() {
            this.search();
        },
        project_id() {
            this.search();
        },
        location() {
            this.search();
        }
    }
}
</script>

<style scoped>
    .rtl {
        text-align: right !important;
    }
    tbody td {
        padding: .5rem;
    }
    .para {
        background-color: rgb(81, 224, 81) !important;
        color: black !important;
    }
    .para-total {
        background-color: rgb(68, 171, 231) !important;
        color: black !important;
    }
    th, td {
        font-size: 12pt;
        color: black;
        font-weight: 500;
    }
    @media print {
        .no-print, #nav {
            display: none !important;
        }
        *:not(.para, .para-total, .expired-passport-date) {
            background-color: white ;
                
        }
        .para {
            background-color: rgb(81, 224, 81) !important;
            color: black !important;
        }
        .para-total {
            background-color: rgb(68, 171, 231) !important;
            color: black !important;
        }
        table {
            font-size: 9pt !important;
        }
        @page {
            size: portrait;
        }
        #wrapper #content-wrapper {
            background-color: white !important;
        }
        /* .header-report {
            position: fixed;
            top: -10px;
        }
        .footer-report {
            position: fixed;
            bottom: 1px;
        }
        .content {
            margin-top: 330px !important;
            border-top: 1px solid;
            padding-top: 6px;
            padding-inline: 20px;
            height: 28.15cm;
            max-height: 28.15cm;
        }
        .px-0-print {
            padding-inline: 0 !important;
        } */
    }
</style>